import axios from "axios";
import { getAuth } from "@firebase/auth";
import router from "@/router/index.ts";
import store from "@/store/store";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

httpClient.interceptors.request.use(async (config) => {
  const user = await getAuth().currentUser;
  if (
    !user &&
    router.currentRoute.value &&
    router.currentRoute.value.meta &&
    router.currentRoute.value.meta.requiresAuth
  ) {
    await store.dispatch("UserModule/logoutUser");
    await store.dispatch("DiscoverModule/resetStore");
    await store.dispatch("EventsModule/resetStore");
    await store.dispatch("SpotifyAccessTokenHelperModule/resetStore");
    await router.push("/sign-in");
  }
  if (config.headers != null && config.headers["X-Requested-With"] == null) {
    if (user) {
      const freshToken = await user.getIdToken();
      config.headers = {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + freshToken,
        ...config.headers,
      };
    }
  }
  return config;
});

export default httpClient;
