import httpClient from "./httpClient";

const USER = "/user";
const USER_SEARCH_HISTORY = `${USER}/user-search-history`;
const USER_WATCHLIST = `${USER}/watch-list`;
// const DISCOGS = "/discogs";
const Save = "/save";

const getFollowedArtists = () => httpClient.get(`${USER}/followed`);

const followArtist = (uuid) =>
  httpClient.post(`${USER}/followed/follow`, {
    uuid: uuid,
  });

const saveArtistApi = (uuid) => httpClient.post(`save/${uuid}`);

const saveMongoArtistDetail = (uuid) =>
  httpClient.post(`/mongo-artist-detail?uuid=${uuid}`);

const unfollowArtist = (uuid) =>
  httpClient.post(`${USER}/followed/un-follow`, {
    uuid: uuid,
  });

const getUserSearchHistory = () => httpClient.get(`${USER_SEARCH_HISTORY}`);

const updateUserSearchHistory = (uuid) =>
  httpClient.post(`${USER_SEARCH_HISTORY}`, {
    uuid,
  });

const updateWatchlistPosition = (watchlistPosition) =>
  httpClient.post(`${USER_WATCHLIST}/update-position`, watchlistPosition);

const getWatchlist = () => httpClient.get(`${USER_WATCHLIST}`);

const addNewWatchlist = (watchlist) =>
  httpClient.post(`${USER_WATCHLIST}`, watchlist);

const addArtistToWatchlist = (artistID, watchlistID) =>
  httpClient.post(`${USER_WATCHLIST}/artist/add-artist`, {
    artist: {
      uuid: artistID,
    },
    userWatchList: {
      id: watchlistID,
    },
  });

const deleteArtistFromWatchlist = (artistID, watchlistID) =>
  httpClient.delete(`${USER_WATCHLIST}/artist/remove-artist`, {
    data: {
      artist: {
        uuid: artistID,
      },
      userWatchList: {
        id: watchlistID,
      },
    },
  });

const deleteUserWatchlist = (watchlistID) =>
  httpClient.delete(`${USER_WATCHLIST}/${watchlistID}`);

const addDiscogsSearchHistory = (discogId) =>
  httpClient.post(`save/${discogId}`);

const associateDiscogsSearchHistory = (payload) =>
  httpClient.post(`save/associate`, payload);

const getAllDiscogsSearchHistory = (offset, limit) =>
  httpClient.get(`${Save}?offset=${offset}&limit=${limit}`);

const deleteDiscogsSearchHistoryById = (id) =>
  httpClient.delete(`${Save}/${id}`);

const deleteBatchDiscogsSearchHistoryId = (ids) =>
  httpClient.post(`${Save}/batch/delete`, { ids });

export {
  getFollowedArtists,
  followArtist,
  unfollowArtist,
  getUserSearchHistory,
  updateUserSearchHistory,
  getWatchlist,
  addNewWatchlist,
  addArtistToWatchlist,
  deleteUserWatchlist,
  deleteArtistFromWatchlist,
  updateWatchlistPosition,
  addDiscogsSearchHistory,
  getAllDiscogsSearchHistory,
  deleteDiscogsSearchHistoryById,
  deleteBatchDiscogsSearchHistoryId,
  saveArtistApi,
  associateDiscogsSearchHistory,
  saveMongoArtistDetail,
};
