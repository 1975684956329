import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { getAuth } from "firebase/auth";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import store from "@/store/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          requiresAuth: true,
          title: "Dashboard",
        },
      },
      {
        path: "/artists",
        name: "artists",
        component: () => import("@/artists/views/Artists.vue"),
        meta: {
          requiresAuth: true,
          title: "Artist",
        },
      },
      {
        path: "/artists/:uuid",
        name: "artistDetail",
        component: () => import("@/artists/views/ArtistDetail.vue"),
        meta: {
          requiresAuth: true,
          title: "Artist Detail",
        },
        children: [
          {
            path: "overview",
            name: "artistOverview",
            component: () => import("@/artists/views/Overview.vue"),
            meta: {
              requiresAuth: true,
              title: "Overview",
            },
          },
          {
            path: "fanbase",
            name: "fanbase",
            component: () => import("@/artists/views/Fanbase.vue"),
            meta: {
              requiresAuth: true,
              title: "Fanbase",
            },
          },
          {
            path: "listeners",
            name: "artistListeners",
            component: () => import("@/artists/views/Listeners.vue"),
            meta: {
              requiresAuth: true,
              title: "Listeners",
            },
          },
          {
            path: "spotify",
            name: "spotify",
            component: () => import("@/artists/views/Spotify.vue"),
            meta: {
              requiresAuth: true,
              title: "Spotify",
            },
          },
          {
            path: "airplay",
            name: "airplay",
            component: () => import("@/artists/views/Airplay.vue"),
            meta: {
              requiresAuth: true,
              title: "Airplay",
            },
          },
          {
            path: "playlist",
            name: "playlist",
            component: () => import("@/artists/views/Playlist.vue"),
            meta: {
              requiresAuth: true,
              title: "Playlist",
            },
          },
          {
            path: "forecast",
            name: "forecast",
            component: () => import("@/artists/views/ArtistForecast.vue"),
            meta: {
              requiresAuth: true,
              title: "Watchlist Forecast",
            },
          },
          {
            path: "analytics",
            name: "analytics",
            component: () => import("@/artists/views/Analytics.vue"),
            meta: {
              requiresAuth: true,
              title: "Analytics",
            },
          },
        ],
      },
      {
        path: "/ai-beta-bench",
        name: "aiBetaBench",
        redirect: "/ai-beta-bench/search-results",
        component: () => import("@/aiBetaBench/views/Index.vue"),
        meta: {
          requiresAuth: true,
          title: "AI Beta Bench",
        },
        children: [
          {
            path: "search-results",
            name: "Search",
            component: () => import("@/aiBetaBench/views/SearchResults.vue"),
            meta: {
              requiresAuth: true,
              title: "Search",
            },
          },
          {
            path: "listeners",
            name: "aiBetaBenchListeners",
            component: () =>
              import("@/aiBetaBench/views/SearchResultListeners.vue"),
            meta: {
              requiresAuth: true,
              title: "Listeners",
            },
          },
          {
            path: "playlist",
            name: "aiBetaBenchPlaylists",
            component: () => import("@/aiBetaBench/views/SearchResults.vue"),
            meta: {
              requiresAuth: true,
              title: "Playlists",
            },
          },
          {
            path: "/followers",
            name: "aiBetaBenchFollowers",
            component: () => import("@/aiBetaBench/views/SearchResults.vue"),
            meta: {
              requiresAuth: true,
              title: "Followers",
            },
          },
          {
            path: "/fanbase",
            name: "aiBetaBenchFanbase",
            component: () => import("@/aiBetaBench/views/SearchResults.vue"),
            meta: {
              requiresAuth: true,
              title: "Fanbase",
            },
          },
        ],
      },
      {
        path: "/crawler",
        name: "crawler",
        component: () => import("../crawler/views/Crawler.vue"),
        meta: {
          requiresAuth: true,
          title: "Crawler",
        },
      },
      {
        path: "/aiGenre",
        name: "aiGenre",
        component: () => import("../aiGenre/views/AiGenre.vue"),
        meta: {
          requiresAuth: true,
          title: "aiGenre",
        },
      },
      {
        path: "/crawler-history",
        name: "crawlerHistory",
        component: () => import("../crawler/views/CrawlerSavedHistory.vue"),
        meta: {
          requiresAuth: true,
          title: "Crawler",
        },
      },
      {
        path: "/aiArtists",
        name: "PreFilledGenres",
        component: () => import("../prefilledGenres/views/PrefilledGenres.vue"),
        meta: {
          requiresAuth: true,
          title: "AI Artists",
        },
      },
      {
        path: "/watchlist",
        name: "watchlists",
        component: () => import("@/user/views/Watchlists.vue"),
        meta: {
          requiresAuth: true,
          title: "Watchlists",
        },
      },
      {
        path: "/watchlist/:id",
        name: "watchlistDetails",
        component: () => import("@/user/views/WatchlistDetails.vue"),
        meta: {
          requiresAuth: true,
          title: "Watchlists",
        },
      },
      {
        path: "/watchlist/:id/forecast",
        name: "watchlistForecast",
        component: () => import("@/user/views/WatchlistForecast.vue"),
        meta: {
          requiresAuth: true,
          title: "Watchlist Forecast",
        },
      },
      {
        path: "/discover",
        name: "discover",
        component: () => import("@/discover/views/Discover.vue"),
        meta: {
          privatePublic: true,
          requiresAuth: false,
          title: "Discover Quickly",
        },
      },
      {
        path: "/callback-spotify",
        name: "callback",
        component: () => import("@/discover/components/CallbackSpotify.vue"),
        meta: {
          privatePublic: true,
          requiresAuth: false,
          title: "Callback",
        },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/profile/views/UserProfile.vue"),
        meta: {
          requiresAuth: true,
          title: "Profile",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("../auth/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("../auth/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("../auth/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("../auth/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const clearSearchQueryIfRequired = (to) => {
  if (
    to.name !== "artists" &&
    to.name !== "discover" &&
    to.name !== "callback"
  ) {
    store.dispatch("EventsModule/clearSearchQuery");
  }
};

const authenticateRoutes = (to, from, next) => {
  const currentUser = getAuth().currentUser;
  const requiresAuth = to.meta.requiresAuth;
  if (!requiresAuth && currentUser && !to.meta.privatePublic) {
    next("/dashboard");
  } else if (!currentUser && requiresAuth) {
    next("/sign-in");
  }
};

const setPageTitle = (to) => {
  if (to.meta.requiresAuth) {
    setCurrentPageTitle(String(to.meta.title));
  }
};

router.beforeEach((to, from, next) => {
  setPageTitle(to);
  clearSearchQueryIfRequired(to);
  authenticateRoutes(to, from, next);
  next();
});

export default router;
