import moment from "moment";
import httpClient from "../../api/httpClient";
import useSpotifyApi from "@/common/composables/useSpotifyApi";
import store from "@/store/store";

const state = {
  spotifyUser: {},
  accessToken: "",
  refreshToken: "",
  tokenExpiryTime: moment().subtract(10, "second").format("YYYY-MM-DD H:m:s"),
  refreshingToken: false,
  redirectPathAfterLogin: null,
};

const getters = {
  isTokenExpired(state) {
    return moment(state.tokenExpiryTime, "YYYY-MM-DD H:m:s").isBefore(moment());
  },
  getAccessToken(state) {
    return state.accessToken;
  },
  getSpotifyUser(state) {
    return state.spotifyUser;
  },
  getRedirectPathAfterLogin(state) {
    return state.redirectPathAfterLogin;
  },
};

const mutations = {
  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken;
  },
  SET_REFRESH_TOKEN(state, refreshToken) {
    state.refreshToken = refreshToken ? refreshToken : state.refreshToken;
  },
  SET_EXPIRY_TIME(state, expiresIn) {
    state.tokenExpiryTime = moment()
      .add(expiresIn - 10, "second")
      .format("YYYY-MM-DD H:m:s");
  },
  SET_REFRESHING_TOKEN(state, payload) {
    state.refreshingToken = payload;
  },
  SET_SPOTIFY_USER(state, payload) {
    state.spotifyUser = payload;
  },
  SET_REDIRECT_PATH_AFTER_LOGIN(state, payload) {
    state.redirectPathAfterLogin = payload;
  },
  RESET_STORE(state) {
    state.accessToken = "";
    state.refreshToken = "";
    state.tokenExpiryTime = moment()
      .subtract(10, "second")
      .format("YYYY-MM-DD H:m:s");
    state.refreshingToken = false;
    state.spotifyUser = {};
  },
};

const actions = {
  async getAccessToken({ state, dispatch, getters }) {
    let count = 0;
    while (state.refreshingToken && count < 10) {
      setTimeout(() => {
        count++;
      }, 1000);
    }
    if (getters.isTokenExpired) {
      await dispatch("refreshAccessToken");
    }
    return state.accessToken;
  },
  async refreshAccessToken({ commit, state }) {
    if (state.refreshToken) {
      commit("SET_REFRESHING_TOKEN", true);
      try {
        const { data } = await httpClient.get(
          `spotify/refresh-access-token?refreshToken=${state.refreshToken}`
        );
        commit("SET_ACCESS_TOKEN", data.accessToken);
        commit("SET_EXPIRY_TIME", data.expiresIn);
        localStorage.setItem("refresh-spotify-token", JSON.stringify(data));
      } catch (e) {
        console.error(e);
      }
      commit("SET_REFRESHING_TOKEN", false);
    }
  },
  async setUserAndToken({ commit }, data) {
    commit("SET_ACCESS_TOKEN", data.accessToken);
    commit("SET_REFRESH_TOKEN", data.refreshToken);
    commit("SET_EXPIRY_TIME", data.expiresIn);
    const spotifyWebAPI = await useSpotifyApi(store);
    const { body } = await spotifyWebAPI.getMe();
    commit("SET_SPOTIFY_USER", body);
  },
  async setAccessTokenByCode({ dispatch }, code) {
    try {
      const { data } = await httpClient.get(
        `spotify/access-token?code=${code}&redirectUrl=${
          window.origin + "/callback-spotify"
        }`
      );
      await dispatch("setUserAndToken", data);
      localStorage.setItem("set-spotify-token", JSON.stringify(data));
      return data;
    } catch (e) {
      console.error(e);
    }
  },
  resetStore({ commit }) {
    commit("RESET_STORE");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
