import {
  getFollowedArtists,
  getWatchlist,
  followArtist,
  unfollowArtist,
  addNewWatchlist,
  addArtistToWatchlist,
  deleteUserWatchlist,
  deleteArtistFromWatchlist,
  getAllDiscogsSearchHistory,
} from "@/api/user.api";

import { getAuth, signOut } from "firebase/auth";

// State Object
const state = {
  followedArtists: [],
  watchlist: [],
  discogsSearchHistory: [],
  crawlerPagination: {
    offset: 1,
    limit: 10,
    total: 0,
  },
};

// Getter functions
const getters = {
  getDiscogsSearchHistory(state) {
    return state.discogsSearchHistory;
  },
  getFollowedArtists(state) {
    return state.followedArtists;
  },
  getWatchlist(state) {
    return state.watchlist;
  },
  getWatchlistById: (state) => (watchlistId) => {
    return state.watchlist.find((watchlist) => watchlist.id === watchlistId);
  },
  getCrawlerPagination(state) {
    return state.crawlerPagination;
  },
};

// Actions
const actions = {
  async fetchFollowedArtists({ commit }) {
    try {
      const { data } = await getFollowedArtists();
      commit("SET_FOLLOWED_ARTISTS", data.data);
    } catch (e) {
      console.error(e);
    }
  },
  async fetchDiscogsSearchHistory({ commit }) {
    // const existingHistory = await getters.getDiscogsSearchHistory;
    // if (existingHistory.length === 0 || forceFetch) {
    try {
      const {
        data: { data },
      } = await getAllDiscogsSearchHistory(
        state.crawlerPagination.offset - 1,
        state.crawlerPagination.limit
      );
      commit("SET_DISCOGS_SEARCH_HISTORY", data.records);
      state.crawlerPagination.total = data.total;
      commit("SET_CRAWLER_PAGINATION", state.crawlerPagination);
    } catch (e) {
      console.error(e);
    }
    // }
  },
  async followArtist({ commit }, artistID) {
    try {
      const { data } = await followArtist(artistID);
      commit("FOLLOW_ARTIST", data.data);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async unfollowArtist({ commit }, artistID) {
    try {
      const { data } = await unfollowArtist(artistID);
      commit("UNFOLLOW_ARTIST", data.data);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  logoutUser() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        //
      })
      .catch((e) => {
        console.error(e);
      });
  },
  resetStore({ commit }) {
    commit("RESET_STORE");
  },
  async fetchWatchlist({ commit }) {
    try {
      const { data } = await getWatchlist();
      commit("MUTATE_WATCHLIST", data.data);
    } catch (e) {
      console.error(e);
    }
  },

  async addWatchlist({ commit }, watchlist) {
    const { data } = await addNewWatchlist(watchlist);
    if (data.statusCode === 422) {
      return false;
    }
    commit("MUTATE_WATCHLIST", data.data);
    const numberOfWatchlist = data.data.length;
    return data.data[numberOfWatchlist - 1].id;
  },

  changeOrderOfWatchlist({ commit }, watchlist) {
    commit("MUTATE_WATCHLIST", watchlist);
  },

  async deleteWatchlist({ commit }, watchlistID) {
    try {
      const { data } = await deleteUserWatchlist(watchlistID);
      commit("MUTATE_WATCHLIST", data.data);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  async addArtistToWatchlist({ commit }, payload) {
    const { data } = await addArtistToWatchlist(
      payload.artistID,
      payload.watchlistID,
      payload.watchlistTags
    );
    commit("MUTATE_WATCHLIST", data.data);
  },
  async deleteArtistFromWatchlist({ commit }, payload) {
    try {
      const { data } = await deleteArtistFromWatchlist(
        payload.artistID,
        payload.watchlistID
      );
      commit("MUTATE_WATCHLIST", data.data);
      return true;
    } catch (e) {
      return false;
    }
  },
};

// Mutations
const mutations = {
  SET_FOLLOWED_ARTISTS(state, followedArtists) {
    state.followedArtists = followedArtists;
  },
  SET_DISCOGS_SEARCH_HISTORY(state, payload) {
    state.discogsSearchHistory = [];
    state.discogsSearchHistory = payload;
  },
  FOLLOW_ARTIST(state, updatedArtistFollowedList) {
    state.followedArtists = [...updatedArtistFollowedList];
  },
  UNFOLLOW_ARTIST(state, updatedArtistFollowedList) {
    state.followedArtists = [...updatedArtistFollowedList];
  },
  MUTATE_WATCHLIST(state, watchlist) {
    state.watchlist = [...watchlist];
  },
  RESET_STORE(state) {
    state.followedArtists = [];
    state.watchlist = [];
    state.discogsSearchHistory = [];
  },
  SET_CRAWLER_PAGINATION(state, payload) {
    state.crawlerPagination = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
