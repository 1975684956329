const state = {
  totalFanbase: 0,
  lastYearRevenues: 0,
  watchListModalArtistUUID: "",
};

const getters = {
  getUUIDToShowWatchListModal(state) {
    return state.watchListModalArtistUUID;
  },
  getTotalFanbase(state) {
    return state.totalFanbase;
  },
  getLastYearReveneus(state) {
    return state.lastYearRevenues;
  },
};

const actions = {
  setFanbase({ commit }, totalFanbase) {
    commit("SET_FANBASE", totalFanbase);
  },
  setLastYearRevenues({ commit }, revenue) {
    commit("SET_LAST_YEAR_REVENUE", revenue);
  },
};

const mutations = {
  SET_SHOW_WATCHLIST_MODAL_ARTIST_UUID(state, payload) {
    state.watchListModalArtistUUID = payload;
  },
  SET_FANBASE(state, totalFanbase) {
    state.totalFanbase = totalFanbase;
  },
  SET_LAST_YEAR_REVENUE(state, revenue) {
    state.lastYearRevenues = revenue;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
