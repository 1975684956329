import * as firebase from "firebase/app";

let firebaseConfig;

if (process.env.VUE_APP_ENVIRONMENT === "staging") {
  firebaseConfig = {
    apiKey: "AIzaSyB2zgOwUPOxWBsei1U8xm8Jb6Nj8B2Nf5U",
    authDomain: "sound-code-909c1.firebaseapp.com",
    projectId: "sound-code-909c1",
    storageBucket: "sound-code-909c1.appspot.com",
    messagingSenderId: "304065151403",
    appId: "1:304065151403:web:9596708250ca65d37412ee",
    measurementId: "G-0YYMV7PZC0",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyD9auu6jJaXMuAXv2W8lozJexgZ5orFG6Q",
    authDomain: "bling-rock.firebaseapp.com",
    projectId: "bling-rock",
    storageBucket: "bling-rock.appspot.com",
    messagingSenderId: "3456420952",
    appId: "1:3456420952:web:7e773b4afd5e52dd124e2d",
  };
}

export default firebase.initializeApp(firebaseConfig);
